import React from 'react';
import steamLogo from '../images/steam_logo.svg';
import metaQuestIcon from '../images/meta-quest-icon.png';
import vrmlIcon from '../images/vrml-icon.png';
import discordIcon from '../images/discord-icon.png';
import instagramIcon from '../images/instagram-icon.png';
import giantScamIcon from '../images/giant-scam-logo.png';
import { DiscordEmbed } from './DiscordEmbed';

export const Footer: React.FC = () => {
  return (
    <>
      <div className="m-5 d-flex flex-justify-content-around">
        <DiscordEmbed />
      </div>
      <nav className="navbar navbar-dark mt-10 pb-5">
        <div className="flex mx-2 mr-auto">
          <span>Play: </span>
          <a className='m-1' href="https://store.steampowered.com/app/1133580/Snapshot_VR/" target="_blank" rel="noopener noreferrer">
            <img height={32} src={steamLogo} alt="Steam" />
          </a>
          <a className='m-1' href="https://www.oculus.com/experiences/quest/6612622932096815?utm_source=snapshotvr.com" target="_blank" rel="noopener noreferrer">
            <img height={32} src={metaQuestIcon} alt="Meta Quest" />
          </a>
        </div>
        <div className="flex mx-2 mr-auto">
          <span>Engage:</span>
          <a className='m-1' href="https://discord.gg/GFNuRaDkfu" target="_blank" rel="noopener noreferrer">
            <img height={32} src={discordIcon} alt="Discord" />
          </a>
          <a className='m-1' href="https://www.instagram.com/giantscam/" target="_blank" rel="noopener noreferrer">
            <img height={32} src={instagramIcon} alt="Instagram" />
          </a>
        </div>
        <div className="flex mx-2 mr-auto">
          <span>Compete:</span>
          <a className='m-1' href="https://vrmasterleague.com/Snapshot/" target="_blank" rel="noopener noreferrer">
            <img height={32} src={vrmlIcon} alt="VRML" />
          </a>
        </div>
        <div className="d-flex flex-justify-around">
          <a href="/redeem" className="m-2">
            Redeem a Code
          </a>
          <a href="privacy.txt" className="m-2">privacy</a>
        </div>
        <a className='m-1' href="http://giantscam.com" target="_blank" rel="noopener noreferrer">
          <img height={32} src={giantScamIcon} alt="Giant Scam" />
        </a>
      </nav>
    </>
  );
};
