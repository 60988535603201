import React from 'react';
import { useAuth } from '../AuthContext';
import { Redirect } from 'react-router-dom';
import jerseyPic from '../images/jersey.png';

export const Login: React.FC = () => {
  const auth = useAuth();
  const { hash } = window.location;

  React.useEffect(() => {
    hash.includes('id_token') && auth.instance.parseCognitoWebResponse(hash);
  }, [hash, auth]);

  if (auth.instance.isUserSignedIn()) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div id="login">
      <div className="flex">
        <a className="flex mx-2" href="https://giantscam.bigcartel.com/">
          <img className="mw-100" src={jerseyPic} alt="Snapshot Jersey" />
          <span className="btn btn-secondary">Limited run of Jerseys available!</span>
        </a>
      </div>
      <div className="videoWrapper">
        <iframe className="videoEmbed" src="https://www.youtube.com/embed/kgNsLdg06ew" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
      </div>
    </div>
  );
};
