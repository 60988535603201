import React from 'react';

export const Tournament: React.FC = () => {
  return (
    <>
        <div className="col-auto mx-auto mb-12">
          <h1>SnapshotVR Steam Open Tournament</h1>
          <p>October 3rd 2020 at 6pm Central US</p>

          <h2>Tournament Format</h2>
          <ol>
            <li>A match consists of 3 maps.  The team to win 2 out of the 3 maps is the winner of the match.  It is not required to play the 3rd map if a team wins the first 2 maps.</li>
            <li>The match format is the default Race to 5 points.</li>
            <li>Maps must be played in this order:
              <ol>
                <li>Map 1 - 2dp_NXL_Spain_2020</li>
                <li>Map 2 - 2dp_2020_MPPL_Event1_v1.2</li>
                <li>Map 3 - 2dp_MSXL_Kentucky_Opener_2018</li>
              </ol>
            </li>
            <li>The official player count is 5v5.  A team may play with 4 members in this tournament if a player is unavailable.</li>
            <li>Rosters may not be changed once the tournament begins.</li>
            <li>The tournament format is Double Elimination.  If you lose, you will be placed in the losers bracket.  If you lose a match in the losers bracket, you are eliminated from the tournament.  The winner of the losers bracket would have to beat the winner of the winners bracket in 2 back-to-back matches to win the tournament.</li>
          </ol>

          <hr/>

          <h2>Teams</h2>
          <div id="teamslist">
            <div>
              <h3>Mod Squad</h3>
              <ul>
                <li>Shiva Soldier</li>
                <li>Chillswitch</li>
                <li>Bushido</li>
                <li>Albifons</li>
                <li>Greehas</li>
              </ul>
            </div>
            <div>
              <h3>Team Murca!!!</h3>
              <ul>
                <li>FoxKru</li>
                <li>a27raptor</li>
                <li>DGxShAdOwZz</li>
                <li>IAmArch</li>
                <li>DesertFoxVR</li>
              </ul>
            </div>
            <div>
              <h3>Team Breakfast</h3>
              <ul>
                <li>MagicBagel</li>
                <li>Timmy</li>
                <li>TR_Myth</li>
                <li>ShowMeVirtual</li>
                <li>FiteTonite</li>
              </ul>
            </div>
            <div>
              <h3>Team Free Agents</h3>
              <ul>
                <li>nuglo</li>
                <li>KittenLover10</li>
                <li>Tara</li>
                <li>Eclipse ApExxx</li>
                <li>Relentless</li>
              </ul>
            </div>
            <div>
              <h3>The Underdogs</h3>
              <ul>
                <li>Marek</li>
                <li>randall</li>
                <li>avocado.png</li>
                <li>GoodOleGimpy</li>
                <li>GreenTeftAuto</li>
              </ul>
            </div>
            <div>
              <h3>Blaze</h3>
              <ul>
                <li>Z3RO</li>
                <li>batman</li>
                <li>silent knight</li>
                <li>cryocell</li>
                <li>Naoko</li>
              </ul>
            </div>
            <div>
              <h3>Trip and the Nips</h3>
              <ul>
                <li>Tripl3 Nippl3</li>
                <li>NightFiree</li>
                <li>SL33PY</li>
                <li>Prefontaine</li>
                <li>PeskyCashew</li>
              </ul>
            </div>
          </div>
        </div>
        <hr/>
        <h2>Bracket</h2>
        <iframe title="bracket" src="https://challonge.com/snapshotvr_steamopen/module" width="100%" height="500" frameBorder="0" scrolling="auto" allowTransparency={true}></iframe>
        * Bracket is not final until tournament starts.
        <hr/>

        <h2>Relevant Rules</h2>
        <ol>
          <li>Voice chat should be limited to in-game only, and teams should not be using a third-party voice chat app during tournament matches.</li>
          <li>All locomotion modes and configurations are allowed and may be changed at will by a player during a match.</li>
          <li>The host is forbidden from modifying any Room-wide setting while a map is being played.</li>
          <li>A player may not change teams at any point after a match has begun.</li>
          <li>Up to 2 Tournament Moderator Spectators may be present in a room, and they must stay on the Spectator team for the full duration of the match.  Non-mod Spectators are not permitted in a room.</li>
          <li>Free cam may only be used by Spectators with VR disabled.</li>
          <li>Modifying your VR play area, or any other attribute of the game that is not configurable using in-game functionality, is forbidden.</li>
          <li>Modifying the contents of any map being used in a match is forbidden.</li>
          <li>Repeated intentional team killing is forbidden.</li>
          <li>A dead or inactive player may not leave the Pit area, where players stage and have access to their settings pedestal.</li>
          <li>A live player may not leave the play area, which is marked by the surrounding forcefield.</li>
          <li>A live player may not have more than 20% of their avatar model inside of map geometry at any time.</li>
          <li>A player may not step more than 2 steps off of their spawn point prior to a round starting.</li>
        </ol>

        <h2>Reporting Rule Offenses</h2>
        <p>Any cheating, exploiting, or contravention of the foregoing rules must be brought to the attention of the Tournament Moderators who have complete discretion on how to impose a penalty. Examples of penalties are: round forfeit, map forfeit, match forfeit, tournament forfeit.  For this reason, it’s highly recommended for all players to record their point of view.</p>
        <ol>
          <li>Cheating is intended to include the intentional or reckless manipulation of the game and its code in such a way as to confer an unfair advantage on one side or the other.</li>
          <li>The term exploit is intended to include the intentional or reckless triggering of code, attribute or function in-game that is otherwise not envisaged as a legitimate feature of the game.</li>
          <li>Non-exhaustive examples include:
            <ul>
              <li>Going outside of map boundaries;</li>
              <li>Clipping, as a deliberate attempt to hide more than half your body inside a solid object in game (rough limit of 20% of one&apos;s body shouldn&apos;t be clipped in a geometry);</li>
              <li>Peeking through solid walls or obstacles;</li>
              <li>Third Party VR or Desktop overlays/applications that affects gameplay mechanics or aesthetics (Personal system monitoring/diagnostic overlays are allowed, provided they do not affect previously stated mechanics or aesthetics);</li>
              <li>Use VPN or any other network manipulation;</li>
              <li>Any other imaginative cheats</li>
            </ul>
          </li>
          <li>Any use of the spectate function by participants during official matches, either directly or indirectly, to obtain an advantage of any kind is classified as cheating.</li>
          <li>Any use of external media streaming (stream sniping) which pertains to the circumstances, content or any facts arising from the match is classified as cheating.</li>
        </ol>

        
        <hr/>
    </>
  );
};
