import React, { useState } from 'react';
import { useAuth } from '../AuthContext';
import { useGetStatus } from '../api';

interface ClaimResponse {
  error?: string;
}

export const RedeemRoute: React.FC = () => {
  const auth = useAuth();
  const { data, loading } = useGetStatus();
  const user = data?.getStatus.user;
  const [ busy, setBusy ] = useState(false);
  const [ code, setCode ] = useState("");

  const Submit = async () => {
    setBusy(true);
    const resource = "https://5xd0e4chtk.execute-api.us-east-1.amazonaws.com/main/claimCode";
    // come back to this to be able to use CloudFront
    //const resource = "https://snapshotvr.com/main/claimCode";
    const options = {
      method: "POST",
      body: JSON.stringify({
        code,
        userId: user?.id
      })
    };
    try {
      const result = await fetch(resource, options);
      const response = await result.json() as ClaimResponse;
      console.log("RESPONSE: ", response);
      if (response.error) {
        alert(`FAILED: ${response.error}`);
      } else {
        alert("You successfully claimed the code!");
        setCode("");
      }
    }
    catch (err) {
      console.error(err);
      alert("Unexpected error");
      setBusy(false);
    }
    finally {
      setBusy(false);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!user) {
    return (
      <div className="card">
        <div className="card-body">
          <h2>Redeem a Code</h2>
          <button
            className="btn btn-primary btn-block w-50 mx-auto"
            onClick={() => auth.instance.getSession()}
          >
            Login to Continue
          </button>
        </div>
      </div>
    );
  }

  const form = busy
    ? (
      <progress className="m-2" />
    )
    : (
      <input type="text" onChange={x => setCode(x.target.value)} value={code} className="m-2" />
    )

  return (
    <div className="card">
      <div className="card-body">
        <h2>Redeem a Code</h2>
        <div className="flex flex-justify-content-around">
          {form}
          <button onClick={Submit} className="btn btn-primary m-2" disabled={busy}>Claim</button>
        </div>
      </div>
    </div>
  );
};
