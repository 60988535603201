import React from 'react';
import { Leaderboard } from './Leaderboard';
import { UserProfile } from './UserProfile';

export const Dashboard: React.FC = () => {
  return (
    <>
      <div className="row">
        <div className="col-auto mx-auto mb-5">
          <UserProfile />
        </div>
        <div className="w-100"></div>
        <div className="col-auto mx-auto mb-5">
          <Leaderboard />
        </div>
      </div>
    </>
  );
};
