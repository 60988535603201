import React from 'react';
import { usePlayerStats } from '../api';
import { killsPerMatch } from '../util';

export const Leaderboard: React.FC = () => {
  const { loading, error, data } = usePlayerStats();
  if (loading) return <>Loading...</>;

  if (error) {
    console.warn(error);
    return null;
  }
  if (!data) return null;

  const {
    listPlayerStats: { items },
  } = data;

  items.sort((a, b) => b && a && b.kills && a.kills ? b.kills - a.kills : 0);

  return (
    <div className="card">
      <div className="card-body">
        <h3>Leaderboard</h3>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Player</th>
              <th>Kills</th>
              <th>Deaths</th>
              <th>Matches Played</th>
              <th>K/D</th>
              <th>K/M</th>
            </tr>
          </thead>
          <tbody>
            {items.filter(i=>i).map((item) => (
              <tr key={item.PlayerId}>
                <td>{item.user.nickname}</td>
                <td>{item.kills}</td>
                <td>{item.deaths}</td>
                <td>{item.matchesPlayed}</td>
                <td>{item.killDeathRatio.toFixed(2)}</td>
                <td>{killsPerMatch(item)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
