import { int } from 'aws-sdk/clients/datapipeline';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { useGetStatus, useSetOwnNickname, Stats } from '../api';
import { killsPerMatch } from '../util';

const VALID_FILETYPES = ['image/png', 'image/jpeg', 'image/jpg'];
const KB = 1000;
const MB = 1000 * KB;
const maxUploadSize = MB;
const AVATAR_SIZE = 512;

export const UserProfile: React.FC = () => {
  const { data, loading, error, refetch } = useGetStatus();
  const user = data?.getStatus.user;
  const [setOwnNickname] = useSetOwnNickname();

  const persistNickname = (nickname: string) => {
    nickname && setOwnNickname({ variables: { nickname } });
    refetch();
  };

  const resizeImageFile = async (inputFile: File, width: int, height: int): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const context = canvas.getContext('2d');
      const img = document.createElement('img');
      img.onload = () => {
        context?.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height);
        canvas.toBlob(blob => blob !== null ? resolve(blob) : reject("Could not resize image"));
      }
      const reader = new FileReader();
      reader.onload = () => img.src = reader.result as string;
      reader.readAsDataURL(inputFile);
    });
  }

  const persistAvatar = async (image?: File) => {
    if (!image) return;
    resizeImageFile(image, AVATAR_SIZE, AVATAR_SIZE).then(async imageBlob => {
      if (!VALID_FILETYPES.includes(imageBlob.type)) {
        alert('Only JPEG and PNG filetypes are supported.');
        return;
      }
      if (imageBlob.size > maxUploadSize) {
        alert('Avatar images must be 1MB or smaller.');
        return;
      }
  
      const uploadUrl =
        (imageBlob.type === 'image/png'
          ? user?.avatar.uploadPngUrl
          : user?.avatar.uploadJpgUrl) ?? '';
  
      if (!uploadUrl) {
        // We didn't get the correct upload urls from graphql. Not sure what to do
        // here.
        return;
      }
      await fetch(uploadUrl, { body: imageBlob, method: 'PUT' });
      refetch();
    });
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  // TODO: handle this better or put an interceptor in all gql requests to
  // redirect after token is denied.
  if (
    error &&
    (error.networkError as { statusCode?: number })?.statusCode === 401
  ) {
    return <Redirect to="/" />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  if (!user) {
    return <div>User Not found.</div>;
  }

  return (
    <div className="card">
      <div className="card-body" id="user-profile">
        <AvatarBox onUpload={persistAvatar} url={user.avatar.url} />
        <div className="side">
          <NicknameBox nickname={user.nickname} onChange={persistNickname} />
          <StatsBox stats={user.stats} />
        </div>
      </div>
    </div>
  );
};

type AvatarBoxProps = { onUpload: (image?: File) => void; url: string };
const AvatarBox: React.FC<AvatarBoxProps> = (props) => {
  const inputRef: React.Ref<HTMLInputElement> = React.useRef(null);

  return (
    <div
      className="profile-avatar"
      style={{ backgroundImage: `url(${props.url}?d=${new Date().getTime()})` }}
    >
      <button
        className="btn btn-light m-2 btn-sm"
        onClick={() => inputRef.current?.click()}
      >
        <i className="fa fa-edit"></i>
      </button>
      <input
        hidden
        ref={inputRef}
        name="avatar_url"
        type="file"
        onChange={(e) => props.onUpload(e.target.files?.[0])}
      />
    </div>
  );
};

type NicknameBoxProps = {
  nickname: string;
  onChange: (nickname: string) => void;
};
const NicknameBox: React.FC<NicknameBoxProps> = (props) => {
  const [editing, setEditing] = React.useState(false);
  const [nickname, setNickname] = React.useState(props.nickname);

  if (!editing) {
    return (
      <div className="nickname-box">
        <span className="h1 m-0">{nickname}</span>
        <button
          onClick={() => setEditing(true)}
          className="btn btn-light btn-sm ml-3"
        >
          <i className="fa fa-edit"></i>
        </button>
      </div>
    );
  }

  return (
    <div className="nickname-box">
      <input
        className="form-control"
        value={nickname}
        type="text"
        onChange={(e) => setNickname(e.target.value)}
      />
      <button
        onClick={() => {
          setEditing(false);
          props.onChange(nickname);
        }}
        className="btn btn-primary ml-3"
      >
        <i className="fa fa-check"></i>
      </button>
    </div>
  );
};

const StatsBox: React.FC<{ stats: Stats | null }> = ({ stats }) => (
  <div className="stats-box">
    <p className="py-1 m-0">Kills: {stats?.kills}</p>
    <hr className="w-100 m-0" />
    <p className="py-1 m-0">Deaths: {stats?.deaths}</p>
    <hr className="w-100 m-0" />
    <p className="py-1 m-0">Matches Played: {stats?.matchesPlayed}</p>
    <hr className="w-100 m-0" />
    <p className="py-1 m-0">K/D: {stats?.killDeathRatio.toFixed(2)}</p>
    <hr className="w-100 m-0" />
    <p className="py-1 m-0">K/M: {killsPerMatch(stats ?? undefined)}</p>
    <hr className="w-100 m-0" />
    <p className="py-1 m-0">-----</p>
    <hr className="w-100 m-0" />
    <p className="py-1 m-0">Level: {stats?.level}</p>
    <hr className="w-100 m-0" />
    <p className="py-1 m-0">XP: {stats?.xp}</p>
  </div>
);

