import React from 'react';
import { useAuth } from '../AuthContext';
import { useHistory } from 'react-router';
import { useGetStatus } from '../api';
import logoBanner from '../images/snapshot-logo-banner.png';
import steamLogo from '../images/steam_logo.svg';
import metaQuestIcon from '../images/meta-quest-icon.png';
import vrmlIcon from '../images/vrml-icon.png';
import discordIcon from '../images/discord-icon.png';
import avatarBlueHead from '../images/bluehead.png';
import giantScamIcon from '../images/giant-scam-logo.png';

export const Navbar: React.FC = () => {
  const auth = useAuth();
  const history = useHistory();
  const { data } = useGetStatus();
  const user = data?.getStatus.user;

  const home = () => history.push("/");
  const dashboard = () => history.push("/dashboard");
  const redeem = () => history.push("/redeem");

  const logout = () => {
    if (window.confirm('Logout. Are you sure?')) {
      auth.instance.signOut();
      history.push('/');
    }
  };

  return (
    <nav className="navbar navbar-dark mb-5">
      <span onClick={home} className="navbar-brand m-1 mr-auto">
        <img alt="Snapshot VR" src={logoBanner} className="mw-100" />
      </span>
      <div className="flex m-2 mx-auto">
        <a className='m-2' href="https://store.steampowered.com/app/1133580/Snapshot_VR/" target="_blank" rel="noopener noreferrer">
          <img height={32} src={steamLogo} alt="Steam" />
        </a>
        <a className='m-2' href="https://www.oculus.com/experiences/quest/6612622932096815?utm_source=snapshotvr.com" target="_blank" rel="noopener noreferrer">
          <img height={32} src={metaQuestIcon} alt="Meta Quest" />
        </a>
      </div>
      <div className="flex m-2 mx-auto">
        <a className='m-2' href="https://vrmasterleague.com/Snapshot/" target="_blank" rel="noopener noreferrer">
          <img height={32} src={vrmlIcon} alt="VRML" />
        </a>
        <a className='m-2' href="https://discord.gg/GFNuRaDkfu" target="_blank" rel="noopener noreferrer">
          <img height={32} src={discordIcon} alt="Discord" />
        </a>
      </div>
      <div className="flex ml-auto">
        <a className='m-1' href="http://giantscam.com" target="_blank" rel="noopener noreferrer">
          <img height={32} src={giantScamIcon} alt="Giant Scam" />
        </a>
        <a className='m-1 btn btn-primary' href="https://giantscam.bigcartel.com/" target="_blank" rel="noopener noreferrer">
          SHOP
        </a>
        <button onClick={redeem} className="btn btn-primary m-1">
          Redeem a Code
        </button>
        {auth.instance.isUserSignedIn() ? (
          <>
            <button onClick={logout} className="btn btn-secondary m-1">
              Logout
            </button>
            <button onClick={dashboard} className="btn">
              <img height={32} src={user?.avatar.url || avatarBlueHead} alt="player icon" />
            </button>
          </>
        ) : (
          <button
            className="btn btn-primary m-1"
            onClick={() => auth.instance.getSession()}
          >
            Login
          </button>
        )}
      </div>
    </nav>
  );
};
