import { ApolloLink, ApolloClient, InMemoryCache } from 'apollo-boost';
import { createAppSyncLink } from 'aws-appsync';
import { createAuthLink } from 'aws-appsync-auth-link';
import { auth } from './AuthContext';

const getAccessToken = async () => {
  const token = auth
    .getCachedSession()
    .getIdToken()
    .getJwtToken();
  return token;
};

const url = process.env.REACT_APP_GRAPHQL_URI as string;
const region = process.env.REACT_APP_AWS_REGION as string;
const authType = 'AMAZON_COGNITO_USER_POOLS';

const link = ApolloLink.from([
  createAuthLink({
    url,
    region,
    auth: { type: authType, jwtToken: getAccessToken },
  }),
  createAppSyncLink({
    url,
    region,
    auth: { type: authType, jwtToken: getAccessToken },
    // Don't know what this is for exactly but it is a required option.
    complexObjectsCredentials: () => null,
  }),
]);

const cache = new InMemoryCache({ addTypename: false });

export const client = new ApolloClient({ link, cache });
