import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

export type Stats = {
  kills: number;
  deaths: number;
  killDeathRatio: number;
  matchesPlayed: number;
  level?: number;
  xp?: number;
};

export type GetStatusResult = {
  user: {
    id: string;
    nickname: string;
    avatar: { url: string; uploadPngUrl: string; uploadJpgUrl: string };
    stats: Stats | null;
  };
};

export const useGetStatus = () =>
  useQuery<{ getStatus: GetStatusResult }>(gql`
    {
      getStatus {
        user {
          id
          nickname
          avatar {
            url
            uploadPngUrl
            uploadJpgUrl
          }
          stats {
            kills
            deaths
            killDeathRatio
            matchesPlayed
            level
            xp
          }
        }
      }
    }
  `);

type ListPlayerStatsResult = {
  nextToken: string;
  items: {
    PlayerId: string;
    kills: number;
    deaths: number;
    matchesPlayed: number;
    killDeathRatio: number;
    user: {
      nickname: string;
    };
  }[];
};
export const usePlayerStats = () =>
  useQuery<{ listPlayerStats: ListPlayerStatsResult }>(gql`
    {
      listPlayerStats {
        items {
          PlayerId
          kills
          deaths
          matchesPlayed
          killDeathRatio
          user {
            nickname
          }
        }
        nextToken
      }
    }
  `);

export type SetOwnNicknameResult = {
  user: {
    id: string;
    nickname: string;
  };
};

export const useSetOwnNickname = () =>
  useMutation<
    { setOwnNickname: SetOwnNicknameResult },
    { nickname: string }
  >(gql`
    mutation SetOwnNickname($nickname: String!) {
      setOwnNickname(input: $nickname) {
        user {
          id
          nickname
        }
      }
    }
  `);
