import React from 'react';
import { Route, Redirect, BrowserRouter, Switch } from 'react-router-dom';
import { Login } from './Login';
import { Dashboard } from './Dashboard';
import { Tournament } from './Tournament';
import { RedeemRoute } from './RedeemRoute';
import { Navbar } from './Navbar';
import { AuthProvider } from '../AuthContext';
import { ApolloProvider } from '@apollo/react-hooks';
import { client } from '../client';
import { Footer } from './Footer';

export const App: React.FC = () => {
  return (
    <>
      <BrowserRouter>
        {/* Something in the appsync/apollo-link setup messes up the types here,
            but it should work regardless. */}
        {/* eslint-disable-next-line */}
        <ApolloProvider client={client as any}>
          <AuthProvider>
            <Navbar />
            <div className="container">
              <Switch>
                <Route path="/" exact component={Login} />
                <Route path="/dashboard" exact component={Dashboard} />
                <Route path="/steamopen" exact component={Tournament} />
                <Route path="/redeem" exact component={RedeemRoute} />
                <Redirect to="/" />
              </Switch>
            </div>
            <Footer />
          </AuthProvider>
        </ApolloProvider>
      </BrowserRouter>
    </>
  );
};
