import React from 'react';
import { CognitoAuthSession, CognitoAuth } from 'amazon-cognito-auth-js';
import { useHistory } from 'react-router';

export const REDIRECT_URI =
  process.env.NODE_ENV === 'production'
    ? 'https://snapshotvr.com'
    : 'http://localhost:3000';

const {
  REACT_APP_COGNITO_CLIENT_ID = '',
  REACT_APP_COGNITO_IDENTITY_POOL_ID = '',
} = process.env;

const authData = {
  ClientId: REACT_APP_COGNITO_CLIENT_ID,
  AppWebDomain: 'account.snapshotvr.com',
  TokenScopesArray: [
    'aws.cognito.signin.user.admin',
    'email',
    'openid',
    'phone',
    'profile',
  ],
  RedirectUriSignIn: REDIRECT_URI,
  RedirectUriSignOut: REDIRECT_URI,
  UserPoolId: REACT_APP_COGNITO_IDENTITY_POOL_ID,
};

export const auth = new CognitoAuth(authData);

type AuthContextType = {
  /** function to be run on auth state change from amplify authenticator. */
  session?: CognitoAuthSession;
  instance: CognitoAuth;
};

const AuthContext = React.createContext<AuthContextType>({ instance: auth });

export const AuthProvider: React.FC = props => {
  const [session, setSession] = React.useState<
    CognitoAuthSession | undefined
  >();
  const history = useHistory();
  auth.userhandler = {
    onSuccess(session) {
      setSession(session);
      history.push('/dashboard');
    },
    onFailure(err) {
      console.warn(err);
      setSession(undefined);
      history.push('/');
    },
  };

  return (
    <AuthContext.Provider value={{ session, instance: auth }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
