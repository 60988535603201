import React from 'react';

export const DiscordEmbed = () => (<>
  <iframe
    title="Discord"
    id="discordFrame"
    src="https://discordapp.com/widget?id=605073897372647435&theme=dark"
    width={350}
    height={500}
    allowTransparency={true}
    frameBorder={0}
  ></iframe>
</>);
